import Feature_AddList from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/AddList/index.vue'
import Feature_Artboard from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Artboard/index.vue'
import Feature_Assistant from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Assistant/index.vue'
import Feature_BlockAddList from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/BlockAddList/index.vue'
import Feature_Clipboard from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Clipboard/index.vue'
import Feature_CommandPalette from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/CommandPalette/index.vue'
import Feature_Comments from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Comments/index.vue'
import Feature_Conversions from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Conversions/index.vue'
import Feature_Debug from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Debug/index.vue'
import Feature_Delete from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Delete/index.vue'
import Feature_Diff from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Diff/index.vue'
import Feature_DraggingOverlay from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/DraggingOverlay/index.vue'
import Feature_Duplicate from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Duplicate/index.vue'
import Feature_Edit from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Edit/index.vue'
import Feature_EditForm from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/EditForm/index.vue'
import Feature_EditableField from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/EditableField/index.vue'
import Feature_EditableMask from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/EditableMask/index.vue'
import Feature_EntityTitle from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/EntityTitle/index.vue'
import Feature_Exit from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Exit/index.vue'
import Feature_FieldAreas from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/FieldAreas/index.vue'
import Feature_Fragments from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Fragments/index.vue'
import Feature_Grid from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Grid/index.vue'
import Feature_Help from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Help/index.vue'
import Feature_History from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/History/index.vue'
import Feature_ImportExisting from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/ImportExisting/index.vue'
import Feature_Library from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Library/index.vue'
import Feature_MediaLibrary from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/MediaLibrary/index.vue'
import Feature_MultiSelect from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/MultiSelect/index.vue'
import Feature_Options from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Options/index.vue'
import Feature_Ownership from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Ownership/index.vue'
import Feature_Preview from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Preview/index.vue'
import Feature_PreviewGrant from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/PreviewGrant/index.vue'
import Feature_ProxyView from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/ProxyView/index.vue'
import Feature_Publish from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Publish/index.vue'
import Feature_ResponsivePreview from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/ResponsivePreview/index.vue'
import Feature_Revert from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Revert/index.vue'
import Feature_Search from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Search/index.vue'
import Feature_Selection from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Selection/index.vue'
import Feature_Settings from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Settings/index.vue'
import Feature_Structure from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Structure/index.vue'
import Feature_TouchActionBar from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/TouchActionBar/index.vue'
import Feature_Tour from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Tour/index.vue'
import Feature_Transform from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Transform/index.vue'
import Feature_Translations from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Translations/index.vue'
import Feature_Validations from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Edit/Features/Validations/index.vue'
import type { BlokkliAdapter } from '#blokkli/adapter'
import type { Viewport } from '#blokkli/constants'
type AdapterMethods = keyof BlokkliAdapter<any>

export const availableFeaturesAtBuild = ["add-list","artboard","assistant","block-add-list","clipboard","command-palette","comments","conversions","debug","delete","diff","dragging-overlay","duplicate","edit","edit-form","editable-field","editable-mask","entity-title","exit","field-areas","fragments","grid","help","history","import-existing","library","media-library","multi-select","options","ownership","preview","preview-grant","proxy_view","publish","responsive-preview","revert","search","selection","settings","structure","touch-action-bar","tour","transform","translations","validations"] as const

export type ValidFeatureKey = "add-list" | "artboard" | "assistant" | "block-add-list" | "clipboard" | "command-palette" | "comments" | "conversions" | "debug" | "delete" | "diff" | "dragging-overlay" | "duplicate" | "edit" | "edit-form" | "editable-field" | "editable-mask" | "entity-title" | "exit" | "field-areas" | "fragments" | "grid" | "help" | "history" | "import-existing" | "library" | "media-library" | "multi-select" | "options" | "ownership" | "preview" | "preview-grant" | "proxy_view" | "publish" | "responsive-preview" | "revert" | "search" | "selection" | "settings" | "structure" | "theme" | "touch-action-bar" | "tour" | "transform" | "translations" | "validations"

type FeatureComponent = {
  id: ValidFeatureKey
  component: any
  requiredAdapterMethods: AdapterMethods[]
  dependencies: ValidFeatureKey[]
  description: string
  label: string
  beta: boolean
  viewports: Viewport[]
}

export const featureComponents: FeatureComponent[] = [
{
  id: "add-list",
  dependencies: [],
  viewports: [],
  component: Feature_AddList,
  requiredAdapterMethods: [],
  label: "Add List",
  beta: false,
  description: "Provides the container to render a list of blocks to add or add actions."
},
{
  id: "artboard",
  dependencies: [],
  viewports: [],
  component: Feature_Artboard,
  requiredAdapterMethods: [],
  label: "Artboard",
  beta: false,
  description: "Wraps the entire page in an artboard that can be zoomed and moved using the mouse."
},
{
  id: "assistant",
  dependencies: [],
  viewports: [],
  component: Feature_Assistant,
  requiredAdapterMethods: ["assistantGetResults","assistantAddBlockFromResult"],
  label: "Assistant",
  beta: false,
  description: "Provides a dynamic add block action to add one or more blocks generated by an AI assistant."
},
{
  id: "block-add-list",
  dependencies: ["add-list"],
  viewports: [],
  component: Feature_BlockAddList,
  requiredAdapterMethods: [],
  label: "Block Add List",
  beta: false,
  description: "Renders a list of block bundles that can be added to the current page."
},
{
  id: "clipboard",
  dependencies: [],
  viewports: [],
  component: Feature_Clipboard,
  requiredAdapterMethods: [],
  label: "Clipboard",
  beta: false,
  description: "Provides clipboard integration to copy/paste existing blocks or paste supported clipboard content like text or images."
},
{
  id: "command-palette",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_CommandPalette,
  requiredAdapterMethods: [],
  label: "Command Palette",
  beta: false,
  description: "Provides a command palette with search to access most UI features with a keyboard."
},
{
  id: "comments",
  dependencies: [],
  viewports: [],
  component: Feature_Comments,
  requiredAdapterMethods: ["loadComments","addComment"],
  label: "Comments",
  beta: false,
  description: "Provides comment functionality for blocks."
},
{
  id: "conversions",
  dependencies: [],
  viewports: [],
  component: Feature_Conversions,
  requiredAdapterMethods: ["getConversions","convertBlocks"],
  label: "Conversions",
  beta: false,
  description: "Provides block actions to convert one or more blocks to a different bundle."
},
{
  id: "debug",
  dependencies: [],
  viewports: [],
  component: Feature_Debug,
  requiredAdapterMethods: [],
  label: "Debug",
  beta: false,
  description: "Provides debugging functionality."
},
{
  id: "delete",
  dependencies: [],
  viewports: [],
  component: Feature_Delete,
  requiredAdapterMethods: ["deleteBlocks"],
  label: "Delete",
  beta: false,
  description: "Provides an action to delete one or more blocks."
},
{
  id: "diff",
  dependencies: [],
  viewports: [],
  component: Feature_Diff,
  requiredAdapterMethods: ["loadStateAtIndex"],
  label: "Diff",
  beta: true,
  description: "Displays a diff of all changes in the edit state."
},
{
  id: "dragging-overlay",
  dependencies: [],
  viewports: [],
  component: Feature_DraggingOverlay,
  requiredAdapterMethods: [],
  label: "Dragging Overlay",
  beta: false,
  description: "Renders an overlay when dragging or placing a block."
},
{
  id: "duplicate",
  dependencies: [],
  viewports: [],
  component: Feature_Duplicate,
  requiredAdapterMethods: ["duplicateBlocks"],
  label: "Duplicate",
  beta: false,
  description: "Provides an action to duplicate one or more blocks in place."
},
{
  id: "edit",
  dependencies: [],
  viewports: [],
  component: Feature_Edit,
  requiredAdapterMethods: ["formFrameBuilder"],
  label: "Edit",
  beta: false,
  description: "Provides an action to edit a block."
},
{
  id: "edit-form",
  dependencies: [],
  viewports: [],
  component: Feature_EditForm,
  requiredAdapterMethods: ["formFrameBuilder"],
  label: "Edit Form",
  beta: false,
  description: "Listens to edit events and renders an iframe containing the edit form."
},
{
  id: "editable-field",
  dependencies: [],
  viewports: [],
  component: Feature_EditableField,
  requiredAdapterMethods: ["updateFieldValue","getEditableFieldConfig"],
  label: "Editable Field",
  beta: false,
  description: "Implements a form overlay to edit a single field of a block."
},
{
  id: "editable-mask",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_EditableMask,
  requiredAdapterMethods: [],
  label: "Editable Mask",
  beta: false,
  description: "Provides a view option to hide non-editable parts of the page."
},
{
  id: "entity-title",
  dependencies: [],
  viewports: [],
  component: Feature_EntityTitle,
  requiredAdapterMethods: [],
  label: "Entity Title",
  beta: false,
  description: "Renders the title and status of the page entity."
},
{
  id: "exit",
  dependencies: [],
  viewports: [],
  component: Feature_Exit,
  requiredAdapterMethods: [],
  label: "Exit",
  beta: false,
  description: "Provides a menu button to exit the editor without saving."
},
{
  id: "field-areas",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_FieldAreas,
  requiredAdapterMethods: [],
  label: "Field Areas",
  beta: false,
  description: "Provides a view option to render the field areas with labels."
},
{
  id: "fragments",
  dependencies: [],
  viewports: [],
  component: Feature_Fragments,
  requiredAdapterMethods: ["fragmentsAddBlock"],
  label: "Fragments",
  beta: false,
  description: "Provides way to add content fragments defined by the frontend."
},
{
  id: "grid",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_Grid,
  requiredAdapterMethods: ["getGridMarkup"],
  label: "Grid",
  beta: false,
  description: "Provides a view option to render a grid."
},
{
  id: "help",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_Help,
  requiredAdapterMethods: [],
  label: "Help",
  beta: false,
  description: "Provides a sidebar pane with helpful information on how to use blokkli."
},
{
  id: "history",
  dependencies: [],
  viewports: [],
  component: Feature_History,
  requiredAdapterMethods: ["setHistoryIndex"],
  label: "History",
  beta: false,
  description: "Implements support for history features (undo, redo, list of mutations)."
},
{
  id: "import-existing",
  dependencies: [],
  viewports: [],
  component: Feature_ImportExisting,
  requiredAdapterMethods: ["getImportItems","importFromExisting"],
  label: "Import existing content",
  beta: false,
  description: "Implements a menu action that renders a dialog to import blocks from another entity."
},
{
  id: "library",
  dependencies: [],
  viewports: [],
  component: Feature_Library,
  requiredAdapterMethods: ["makeBlockReusable","detachReusableBlock"],
  label: "Library",
  beta: false,
  description: "Implements support for a block library to manage reusable blocks."
},
{
  id: "media-library",
  dependencies: [],
  viewports: [],
  component: Feature_MediaLibrary,
  requiredAdapterMethods: ["mediaLibraryGetResults","mediaLibraryAddBlock"],
  label: "Media Library",
  beta: false,
  description: "Implements a media library to easily drag and drop media like images or videos."
},
{
  id: "multi-select",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_MultiSelect,
  requiredAdapterMethods: [],
  label: "Multiselect",
  beta: false,
  description: "Implements support for selecting multiple blocks using a select rectangle."
},
{
  id: "options",
  dependencies: [],
  viewports: [],
  component: Feature_Options,
  requiredAdapterMethods: ["updateOptions"],
  label: "Options",
  beta: false,
  description: "Renders the options form for one or more blocks."
},
{
  id: "ownership",
  dependencies: [],
  viewports: [],
  component: Feature_Ownership,
  requiredAdapterMethods: ["takeOwnership"],
  label: "Ownership",
  beta: false,
  description: "Renders a large button to take ownership of the current edit state."
},
{
  id: "preview",
  dependencies: [],
  viewports: [],
  component: Feature_Preview,
  requiredAdapterMethods: [],
  label: "Preview",
  beta: false,
  description: "Provides a button to open a preview in a new window."
},
{
  id: "preview-grant",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_PreviewGrant,
  requiredAdapterMethods: ["getPreviewGrantUrl"],
  label: "Preview Grant",
  beta: false,
  description: "Provides a button to open a dialog with a QR code to preview the page on a smartphone."
},
{
  id: "proxy_view",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_ProxyView,
  requiredAdapterMethods: [],
  label: "Proxy View",
  beta: false,
  description: "Displays the blocks as a structure in the preview."
},
{
  id: "publish",
  dependencies: [],
  viewports: [],
  component: Feature_Publish,
  requiredAdapterMethods: ["publish"],
  label: "Publish",
  beta: false,
  description: "Provides a menu button to publish the changes of the current entity."
},
{
  id: "responsive-preview",
  dependencies: [],
  viewports: ["desktop"],
  component: Feature_ResponsivePreview,
  requiredAdapterMethods: [],
  label: "Responsive Preview",
  beta: false,
  description: "Provides a responsive preview of the current edit state in an iframe."
},
{
  id: "revert",
  dependencies: [],
  viewports: [],
  component: Feature_Revert,
  requiredAdapterMethods: ["revertAllChanges"],
  label: "Revert",
  beta: false,
  description: "Provides a menu button to revert all changes done on the current entity."
},
{
  id: "search",
  dependencies: [],
  viewports: [],
  component: Feature_Search,
  requiredAdapterMethods: [],
  label: "Search",
  beta: false,
  description: "Provides an overlay with shortcut to search for blocks on the current page or existing content to add as blocks."
},
{
  id: "selection",
  dependencies: [],
  viewports: [],
  component: Feature_Selection,
  requiredAdapterMethods: [],
  label: "Selection",
  beta: false,
  description: "Renders an overlay that highlights the selected blocks."
},
{
  id: "settings",
  dependencies: [],
  viewports: [],
  component: Feature_Settings,
  requiredAdapterMethods: [],
  label: "Settings",
  beta: false,
  description: "Provides a menu button to display a settings dialog."
},
{
  id: "structure",
  dependencies: [],
  viewports: [],
  component: Feature_Structure,
  requiredAdapterMethods: [],
  label: "Structure",
  beta: false,
  description: "Provides a sidebar button to render a structured list of all blocks on the current page."
},
{
  id: "touch-action-bar",
  dependencies: [],
  viewports: [],
  component: Feature_TouchActionBar,
  requiredAdapterMethods: [],
  label: "Touch Action Bar",
  beta: false,
  description: "Renders a button on touch devices to cancel touch actions."
},
{
  id: "tour",
  dependencies: [],
  viewports: [],
  component: Feature_Tour,
  requiredAdapterMethods: [],
  label: "Tour",
  beta: false,
  description: "Provides a tour overlay to get to know the editor."
},
{
  id: "transform",
  dependencies: [],
  viewports: [],
  component: Feature_Transform,
  requiredAdapterMethods: ["getTransformPlugins","applyTransformPlugin"],
  label: "Transform",
  beta: false,
  description: "Provides integration for block transform plugins."
},
{
  id: "translations",
  dependencies: [],
  viewports: [],
  component: Feature_Translations,
  requiredAdapterMethods: ["changeLanguage"],
  label: "Translations",
  beta: false,
  description: "Adds support for block translations."
},
{
  id: "validations",
  dependencies: [],
  viewports: [],
  component: Feature_Validations,
  requiredAdapterMethods: [],
  label: "Validations",
  beta: false,
  description: "Provides a sidebar pane to render validations."
}
]
